body.dark-mode .targetElement {
  background: #000000;
}

/*CSS for Dark Mode Toggle

Copy and paste this code into it's own dark.css file. Keep all you dark mode
styles there, and make sure when you link to this sheet in your html head,
make it the bottom css link tag so it overrides all other styles.
At the bottom, that's where you start to add your dark mode styles by
starting starting with "body.dark-mode" and add your class you want to
target at the end. For example,

body.dark-mode .heading-one {}

then add your css properties like normal. That's it!
Scroll to the bottom to start adding your dark mode styles

*/

/* note that Internet Explorer does not support css variables */
:root {
  --dark: #000;
  --medium: #1b1b1b;
  --light: #2e2e2e;
}

.dark-mode-button {
  background: transparent;
  border: none;
}

.top-dark-mode-button .dark-toggle {
  background: rgb(230, 230, 230);
}

.dark-toggle {
  background: transparent;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1000;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  align-items: center;
}

body.dark-mode .dark-toggle {
  background: #004eff;
  background: -o-linear-gradient(
    left,
    rgba(0, 78, 255) 0%,
    rgba(0, 78, 255) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 78, 255)),
    to(rgba(0, 78, 255))
  );
  background: linear-gradient(
    90deg,
    rgba(0, 78, 255) 0%,
    rgba(0, 78, 255) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#43b7ff",endColorstr="#00ddf6",GradientType=1);
  border: none;
}

.dark-toggle span {
  border-radius: 50%;
  background: #fff;
  position: absolute;
}

body.dark-mode .dark-toggle span {
  left: 15px;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.top-dark-mode-button .dark-toggle {
  background: rgb(230, 230, 230);

  width: 35px;
}

/* Begin adding your dark mode styles here, like so: */

body.dark-mode {
  background: #11172c;
}

body.dark-mode .ant-layout {
  background-color: #11172c;
}
body.dark-mode .ant-layout-content {
  background-color: #11172c;
}
body.dark-mode .ant-layout-header {
  background-color: #11172c;
  background: #11172c;
}
body.dark-mode .ant-layout-footer {
  background-color: #11172c;
}
body.dark-mode .ant-menu {
  background-color: #11172c;
}
body.dark-mode .ant-card-body {
  background-color: #11172c;
}
body.dark-mode .ant-modal-body {
  background-color: #11172c;
}
body.dark-mode .ant-drawer-content {
  background-color: #11172c;
}

body.dark-mode .mobilelanding {
  background-color: #11172c;
  background-image: none;
}

body.dark-mode .ant-typography {
  color: #fff;
}
body.dark-mode p {
  color: #fff;
}
body.dark-mode li {
  color: #fff;
}
body.dark-mode .ant-card-meta-title {
  color: #fff;
}
body.dark-mode .ant-card-meta-description {
  color: #fff;
}
