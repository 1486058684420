.ant-layout-header {
  background-color: transparent;
  background: transparent;
  position: fixed;
  z-index: 50;
  width: 100vw;
}
.ant-layout {
  background-color: #ffffff;
}
.ant-layout-footer {
  background-color: #ffffff;
}
.ant-menu {
  transition: none;
}

@media screen and (max-width: 767px) {
  .headercontainer {
    background-color: #004eff;
    background: #004eff;
    position: fixed;
    z-index: 50;
    width: 100vw;
    height: 5vh;
    padding: 0px 25px;
  }
  .ant-layout-header {
    background-color: #004eff;
    background: #004eff;
    position: fixed;
    z-index: 50;
    width: 100vw;
    height: 5vh;
    padding: 0px 25px;
  }
}

div {
  font-family: 'Work Sans';
}

.body {
  font-family: 'Work Sans';
}

.a:link {
  color: #004eff;
}
.a.ant-typography,
.ant-typography a {
  color: #004eff;
}
.bar {
  content: '';
  display: block;
  height: 25vh;
  width: 2px;
  background-color: #004eff;
  position: relative;
  margin-left: 50%;
  margin-top: 10vh;
  margin-bottom: 10vh;
}
.shortbar {
  content: '';
  display: block;
  width: 3vh;
  height: 5px;
  background-color: #004eff;
  position: relative;
  margin-bottom: 3vh;
}
.longbar {
  content: '';
  display: block;
  height: 50vh;
  width: 2px;
  background-color: #004eff;
  position: relative;
  margin-left: 50%;
  margin-top: 10vh;
  margin-bottom: 10vh;
}
.avatarcontainer {
  animation-name: test;
  animation-duration: 2.5s;
  animation-delay: 1s;
  visibility: visible;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
}
.citycontainer {
  animation-name: test;
  animation-duration: 2.5s;
  animation-delay: 3s;
  visibility: visible;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.45s ease-in-out;
  -moz-transition: opacity 0.45s ease-in-out;
  -webkit-transition: opacity 0.45s ease-in-out;
}
.jeremy:hover .avatarcontainer {
  opacity: 0.8;
}
.losangeles:hover .citycontainer {
  opacity: 0.8;
}

@keyframes test {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

@keyframes testfadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes testfadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes testfadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes testfadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes testfadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.jeremy {
  z-index: 2;
  cursor:default;
  
  text-decoration-color: #004eff;
}
.losangeles {
  text-decoration: underline;

  cursor:default;
  text-decoration-color: #004eff;
}
.jeremy:hover {
  transition-timing-function: ease-in-out;
  transition: 0.3s;
}
.losangeles:hover {
  text-decoration-color: #004eff;
  transition-timing-function: ease-in-out;
  transition: 0.3s;
}

.jeremyavatar {
  z-index: 1;
  border-radius: 50%;
  height: 28rem;
  width: 28rem;
}
.jeremyavatarlefticon {
  position: absolute;
  z-index: 1;
  height: 10rem;
  width: 10rem;
  left: -8rem;
  top: 5rem;
}
.jeremyavatarrighticon {
  position: absolute;
  z-index: 1;
  height: 10rem;
  width: 10rem;
  right: -5rem;
}
.cityavatarlefticon {
  position: absolute;
  z-index: 1;
  height: 8rem;
  width: 8rem;
  left: -2rem;
  bottom: 1rem;
}
.cityavatarrighticon {
  position: absolute;
  z-index: 1;
  height: 8rem;
  width: 8rem;
  right: 0rem;
}
.cityavatarbottomrighticon {
  position: absolute;
  z-index: 1;
  height: 9rem;
  width: 9rem;
  right: -3rem;
  bottom: 0rem;
}

.cityavatar {
  z-index: 1;
  border-radius: 50%;
  height: 28rem;
  width: 28rem;
}

.resumelefticon {
  position: relative;
  z-index: 1;
  height: 10rem;
  width: 10rem;
  left: -8rem;
  top: 5rem;
}
.resumelefticonmobile {
  position: relative;
  z-index: 1;
  height: 8rem;
  width: 8rem;
  right: -12rem;
  top: 6.5rem;

  transform: scaleX(-1);
}

@keyframes dash {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.resumerighticon {
  position: relative;
  z-index: 1;
  height: 2.5rem;
  width: 2.5rem;
  right: -53vw;
  bottom: 15rem;
}
.resumerighticonmobile {
  position: relative;
  z-index: 1;
  height: 2.5rem;
  width: 2.5rem;
  right: -70vw;
}
.aboutlefticon {
  z-index: 1;
  height: 4rem;
  width: 4rem;
  left: -2rem;
  bottom: 2rem;
}
.footer-container {
  margin-bottom: 5vh;
}
.input {
  color: white;
  margin: 1rem;
}
.input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.ant-result-title {
  color: white;
}

.fade-in-section {
  opacity: 0;

  visibility: hidden;
  transition: opacity 1.5s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-slide-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, visibility;
}
.fade-slide-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.landing {
  animation-name: testfadein;
  animation-duration: 1s;
  transition: opacity 0.45s ease-in;
  -moz-transition: opacity 0.45s ease-in;
  -webkit-transition: opacity 0.45s ease-in;
}
.contact-success {
  animation-name: testfadein;
  animation-duration: 1s;
  transition: opacity 0.45s ease-in;
  -moz-transition: opacity 0.45s ease-in;
  -webkit-transition: opacity 0.45s ease-in;
}

.mobilelandingtext {
  animation-name: testfadein;
  animation-duration: 1s;
  transition: opacity 0.45s ease-in;
  -moz-transition: opacity 0.45s ease-in;
  -webkit-transition: opacity 0.45s ease-in;
}
.mobilelanding {
  background-image: url('https://s3-us-west-1.amazonaws.com/jeremyho.dev/assets/gradient+copy.png');
  background-size: 'cover';
}

.contact-title {
  visibility: hidden;
  color: #fff;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
}
.contact-title.is-visible {
  transform: none;
  visibility: visible;
  animation: typing 2.5s steps(30, end);
  display: flex;
  justify-content: center;
}
.contact-box {
  visibility: hidden;
  color: #fff;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
}
.contact-box.is-visible {
  transform: none;
  visibility: visible;
  animation: typing 1.5s steps(30, end);
  display: flex;
  justify-content: center;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.mobilecontactform {
  visibility: hidden;
  opacity: 0;
}
.mobilecontactform.is-visible {
  visibility: visible;
  opacity: 0;
  animation-delay: 2s;
  animation: fadeIn 1s ease-in-out both;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.arrowanimation {
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  25% {
    transform: scale(1.15);
    shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  50% {
    transform: scale(0.95);
    shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  75% {
    transform: scale(1.15);
    shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
